"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestErrorDefault = exports.LogoutMessages = exports.PowerBiMessages = exports.RegisterMessages = void 0;
exports.RegisterMessages = {
    passwordAndConfirmPasswordMustBeEquals: 'Senha e confirmar senha devem ser iguais',
    validationRequired: 'O campo é obrigatório',
    validationConfirmPassword: 'As senhas não conferem',
    validationCharactersMinimum6: 'Mínimo de 6 caracteres',
    validationCharactersMaximum200: 'Máximo de 200 caracteres',
    successfulRegistration: 'Cadastro realizado com sucesso',
    emailErrorWhileSignUp: 'E-mail já utilizado na plataforma',
    userPreRegisterSuccess: 'Pré-registro do usuário realizado com sucesso',
    userPreRegisterDefaultError: 'Erro ao salvar os dados.',
    userErrorGetDatas: 'Erro ao buscar os dados. Tente novamente mais tarde.',
    validationOldPasswordEqualsNewPassword: 'A nova senha não pode ser igual a antiga.',
    successfulEdit: 'Edição realizada com sucesso',
    resendEmailUser: 'Email foi reenviado com sucesso!',
    resendEmailUserError: 'Ocorreu um erro ao tentar reenviar email para o usuário.',
    email: {
        insertYourEmail: 'Por favor, insira seu email.',
        invalidEmailFormat: 'Formato de email inválido.',
        dontUseSpecialCharacters: 'Não utilize caracteres especiais, ou acentuados.',
    },
};
exports.PowerBiMessages = {
    errorGenerateTokenPowerBi: 'Houve um erro ao tentar gerar sua dashboard! Por favor, tente mais tarde',
};
exports.LogoutMessages = {
    successLogout: 'Logout realizado com sucesso.',
    errorTokenLogout: 'Erro ao tentar realizar logout.',
    tokenErrorLogout: 'Token não encontrado.',
};
exports.RequestErrorDefault = 'Erro ao buscar os dados. Tente novamente mais tarde.';
