"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const app_1 = require("../actiontypes/app");
const INITIAL_STATE = {
    loading: false,
    collapsed: false,
    headerTitle: '',
};
const app = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case app_1.APP_LOADING_BEGIN:
            return Object.assign(Object.assign({}, state), { loading: true });
        case app_1.APP_LOADING_END:
            return Object.assign(Object.assign({}, state), { loading: false });
        case app_1.TOGGLE_COLLAPSED:
            return Object.assign(Object.assign({}, state), { collapsed: !state.collapsed });
        case app_1.CHANGE_HEADER_TITLE:
            return Object.assign(Object.assign({}, state), { headerTitle: action.headerTitle });
        default:
            return state;
    }
};
exports.default = app;
