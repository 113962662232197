"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("../../../components");
const RoutesPaths_1 = require("../../../routes/RoutesPaths");
require("./PreUserForm.scss");
const users_1 = require("../../../utils/users");
const strings_1 = require("../../../constants/strings");
const general_1 = require("../../../constants/general");
const httpService_1 = require("../../../services/httpService");
const PreUserForm = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [form] = antd_1.Form.useForm();
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const initialData = { typePerson: 'PHYSICAL_PERSON' };
    const [showTypePersonIsPhysical, setShowTypePersonIsPhysical] = (0, react_1.useState)(true);
    const token = (0, users_1.getAccessToken)();
    const createUser = (newUser) => {
        const httpService = new httpService_1.HttpService();
        try {
            httpService
                .post('/users', newUser)
                .then(response => {
                antd_1.message.success(strings_1.RegisterMessages.userPreRegisterSuccess);
                navigate(RoutesPaths_1.routesPaths.userList);
            })
                .catch(error => {
                if (error.response) {
                    if (error.response.status === 406) {
                        antd_1.message.error(strings_1.RegisterMessages.emailErrorWhileSignUp);
                    }
                    else {
                        antd_1.message.error(strings_1.RegisterMessages.userPreRegisterDefaultError);
                    }
                }
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
        catch (error) {
            console.log(error);
        }
    };
    function handleSubmit() {
        const newUser = form.getFieldsValue(['email', 'password', 'fullName', 'companyName', 'typePerson']);
        newUser.typePerson === 'PHYSICAL_PERSON' ? (newUser.companyName = '') : (newUser.fullName = '');
        createUser(newUser);
    }
    const onChangeRadio = (currentRadio) => {
        currentRadio.target.value === 'PHYSICAL_PERSON'
            ? setShowTypePersonIsPhysical(true)
            : setShowTypePersonIsPhysical(false);
        form.setFieldsValue({
            typePerson: currentRadio.target.value,
        });
    };
    return (react_1.default.createElement("div", { className: "PreUserForm" },
        react_1.default.createElement(components_1.MainWrapper, null,
            react_1.default.createElement("div", { className: "form-container" },
                react_1.default.createElement(antd_1.Form, { form: form, onFinish: handleSubmit, initialValues: initialData, labelAlign: "left", layout: "vertical", autoComplete: "off", noValidate: true },
                    react_1.default.createElement(antd_1.Col, null,
                        react_1.default.createElement("h1", { className: "title-form" }, "Pr\u00E9-Cadastro")),
                    react_1.default.createElement(antd_1.Col, null,
                        react_1.default.createElement(antd_1.Col, null,
                            react_1.default.createElement("h1", { className: "radio-group-title" }, "Tipo de Pessoa")),
                        react_1.default.createElement(antd_1.Radio.Group, { className: "radio-group", onChange: valueChanged => onChangeRadio(valueChanged), defaultValue: general_1.PessoaTipo.FISICA },
                            react_1.default.createElement(antd_1.Radio, { value: general_1.PessoaTipo.FISICA }, "F\u00EDsica"),
                            react_1.default.createElement(antd_1.Radio, { value: general_1.PessoaTipo.JURIDICA }, "J\u00FAridica"))),
                    react_1.default.createElement(antd_1.Col, null, showTypePersonIsPhysical ? (react_1.default.createElement(antd_1.Form.Item, { name: "fullName", rules: [
                            {
                                required: true,
                                message: 'Insira o Nome completo',
                            },
                        ] },
                        react_1.default.createElement(antd_1.Input, { className: "input-field", type: "text", placeholder: "Nome completo", id: "input-fullname" }))) : (react_1.default.createElement(antd_1.Form.Item, { name: "companyName", rules: [
                            {
                                required: true,
                                message: 'Insira o Nome completo',
                            },
                        ] },
                        react_1.default.createElement(antd_1.Input, { className: "input-field", type: "text", placeholder: "Nome da empresa", id: "input-companyname" })))),
                    react_1.default.createElement(antd_1.Col, null,
                        react_1.default.createElement(antd_1.Form.Item, { name: "email", rules: [
                                {
                                    required: true,
                                    message: strings_1.RegisterMessages.email.insertYourEmail,
                                },
                                {
                                    type: 'email',
                                    message: strings_1.RegisterMessages.email.invalidEmailFormat,
                                },
                                {
                                    pattern: general_1.PATTERN_EMAIL,
                                    message: strings_1.RegisterMessages.email.dontUseSpecialCharacters,
                                },
                            ] },
                            react_1.default.createElement(antd_1.Input, { className: "input-field", type: "email", placeholder: "E-mail", id: "input-email" }))),
                    react_1.default.createElement(antd_1.Col, null,
                        react_1.default.createElement("div", { className: "field-container-half" },
                            react_1.default.createElement(antd_1.Form.Item, { name: "password", label: "", rules: [
                                    {
                                        required: true,
                                        message: strings_1.RegisterMessages.validationRequired,
                                    },
                                    {
                                        min: 6,
                                        message: strings_1.RegisterMessages.validationCharactersMinimum6,
                                    },
                                ] },
                                react_1.default.createElement(antd_1.Input.Password, { placeholder: "Senha", className: "InputField", id: "input-password" }))),
                        react_1.default.createElement("div", { className: "field-container-half" },
                            react_1.default.createElement(antd_1.Form.Item, { name: "confirmPasswordField", label: "", dependencies: ['password'], rules: [
                                    {
                                        required: true,
                                        message: strings_1.RegisterMessages.validationRequired,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(strings_1.RegisterMessages.validationConfirmPassword);
                                        },
                                    }),
                                ] },
                                react_1.default.createElement(antd_1.Input.Password, { placeholder: "Confirmar Senha", className: "InputField", id: "input-confirm-password" })))),
                    react_1.default.createElement(antd_1.Row, null,
                        react_1.default.createElement(antd_1.Col, { className: "container-buttons" },
                            react_1.default.createElement(antd_1.Button, { className: "SecondaryButton cancel", id: "button-cancel-pre-register", onClick: () => {
                                    navigate(RoutesPaths_1.routesPaths.userList);
                                } }, "Cancelar"),
                            react_1.default.createElement(antd_1.Button, { className: "PrimaryButton save", type: "primary", htmlType: "submit", id: "button-save-pre-register", loading: isLoading },
                                react_1.default.createElement("span", null, !isLoading && 'Salvar')))))))));
};
exports.default = PreUserForm;
