"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routesPaths = void 0;
exports.routesPaths = {
    root: '/',
    initial: '/initial',
    myAccount: '/minha-conta',
    userList: '/usuario/listar',
    userRegister: '/usuario/registrar',
    userEdit: '/usuario/editar',
    loginPortal: '/login-portal',
    userPreRegister: '/usuario/pre-registro',
    resetPassword: '/reset-password',
};
