"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCNPJ = exports.validateURL = exports.validateCPF = void 0;
const format_1 = require("./format");
const validateCPF = (value) => {
    const strCPF = (0, format_1.getOnlyNumbers)(value);
    if (strCPF.length > 11) {
        return false;
    }
    let soma = 0;
    let resto;
    if (strCPF === '00000000000')
        return false;
    if (strCPF === '11111111111')
        return false;
    if (strCPF === '22222222222')
        return false;
    if (strCPF === '33333333333')
        return false;
    if (strCPF === '44444444444')
        return false;
    if (strCPF === '55555555555')
        return false;
    if (strCPF === '66666666666')
        return false;
    if (strCPF === '77777777777')
        return false;
    if (strCPF === '88888888888')
        return false;
    if (strCPF === '99999999999')
        return false;
    for (let i = 1; i <= 9; i += 1)
        soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10)
        resto = 0;
    if (resto !== parseInt(strCPF.substring(9, 10), 10))
        return false;
    soma = 0;
    for (let i = 1; i <= 10; i += 1)
        soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto === 10)
        resto = 0;
    return resto === parseInt(strCPF.substring(10, 11), 10);
};
exports.validateCPF = validateCPF;
const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj == '')
        return false;
    if (cnpj.length != 14)
        return false;
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;
    return true;
};
exports.validateCNPJ = validateCNPJ;
const validateURL = (str) => {
    const patternURL = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!patternURL.test(str);
};
exports.validateURL = validateURL;
