"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zeroPad = exports.maxCharacters = exports.getOnlyNumbers = exports.getFormattedDouble = exports.formatTelephoneOrCellphone = exports.formatTelephone = exports.formatCPF = exports.formatCNPJ = exports.formatCEP = exports.formatCellphone = void 0;
const getOnlyNumbers = (value) => value.toString().replace(/\D/g, '');
exports.getOnlyNumbers = getOnlyNumbers;
const formatCellphone = (cellphone) => {
    const value = getOnlyNumbers(cellphone); // Only numbers
    if (value.length === 0) {
        return '';
    }
    if (value.length === 1 || value.length === 2) {
        return `(${value.slice(0, 2)}`;
    }
    if (value.length >= 3 && value.length <= 7) {
        return `(${value.slice(0, 2)}) ${value.slice(2, 7)}`;
    }
    return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
};
exports.formatCellphone = formatCellphone;
const formatCEP = (cep) => {
    const numbers = getOnlyNumbers(cep); // Only numbers
    if (numbers.length === 0) {
        return '';
    }
    if (numbers.length >= 1 && numbers.length <= 5) {
        return `${numbers.slice(0, 5)}`;
    }
    return `${numbers.slice(0, 5)}-${numbers.slice(5, 8)}`;
};
exports.formatCEP = formatCEP;
const formatCNPJ = (cnpj) => {
    const numbers = getOnlyNumbers(cnpj); // Only numbers
    if (numbers.length === 0) {
        return '';
    }
    if (numbers.length >= 1 && numbers.length <= 2) {
        return `${numbers.slice(0, 2)}`;
    }
    if (numbers.length >= 3 && numbers.length <= 5) {
        return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}`;
    }
    if (numbers.length >= 6 && numbers.length <= 8) {
        return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5, 8)}`;
    }
    if (numbers.length >= 9 && numbers.length <= 12) {
        return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5, 8)}/${numbers.slice(8, 12)}`;
    }
    return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5, 8)}/${numbers.slice(8, 12)}-${numbers.slice(12, 14)}`;
};
exports.formatCNPJ = formatCNPJ;
const formatCPF = (cpf) => {
    const numbers = getOnlyNumbers(cpf);
    if (numbers.length === 0) {
        return '';
    }
    if (numbers.length >= 1 && numbers.length <= 3) {
        return `${numbers.slice(0, 3)}`;
    }
    if (numbers.length >= 4 && numbers.length <= 6) {
        return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}`;
    }
    if (numbers.length >= 7 && numbers.length <= 9) {
        return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(6, 9)}`;
    }
    return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(6, 9)}-${numbers.slice(9, 11)}`;
};
exports.formatCPF = formatCPF;
const formatTelephone = (telephone) => {
    const value = getOnlyNumbers(telephone); // Only numbers
    if (value.length === 0) {
        return '';
    }
    if (value.length === 1 || value.length === 2) {
        return `(${value.slice(0, 2)}`;
    }
    if (value.length >= 3 && value.length <= 6) {
        return `(${value.slice(0, 2)}) ${value.slice(2, 6)}`;
    }
    return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`;
};
exports.formatTelephone = formatTelephone;
const formatTelephoneOrCellphone = (telephone) => {
    const value = getOnlyNumbers(telephone); // Only numbers
    if (value.length === 0) {
        return '';
    }
    if (value.length === 1 || value.length === 2) {
        return `(${value.slice(0, 2)}`;
    }
    if (value.length >= 3 && value.length <= 6) {
        return `(${value.slice(0, 2)}) ${value.slice(2, 6)}`;
    }
    if (value.length <= 10) {
        return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`;
    }
    return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
};
exports.formatTelephoneOrCellphone = formatTelephoneOrCellphone;
const getFormattedDouble = (value) => {
    const floatValue = Number.parseFloat((Math.round(Number(value) * 100) / 100).toString());
    if (Number.isNaN(floatValue)) {
        throw new Error('Not-A-Number');
    }
    return floatValue.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
exports.getFormattedDouble = getFormattedDouble;
const maxCharacters = (str, max) => {
    if (str.length > max) {
        return str.substring(0, max);
    }
    return str;
};
exports.maxCharacters = maxCharacters;
const zeroPad = (num, places) => String(num).padStart(places, '0');
exports.zeroPad = zeroPad;
