"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateExpirationToken = exports.getTokenPowerBi = exports.saveTokenPowerBi = exports.saveUserToken = exports.saveUserData = exports.removeUserFromStorage = exports.getUserRole = exports.getUserPersonalData = exports.getAccessToken = exports.emptyUser = exports.checkUserIsAuth = void 0;
const storage_1 = require("./storage");
const emptyUser = {
    email: '',
    fullName: '',
    id: 0,
    permission: '',
    phantasyName: '',
    userStatus: '',
};
exports.emptyUser = emptyUser;
const removeUserFromStorage = () => {
    Object.values(storage_1.storageKeys.user).forEach((userStorageValue) => {
        (0, storage_1.removeFromStorage)(userStorageValue);
    });
    window.dispatchEvent(new CustomEvent('removeUserFromStorage'));
};
exports.removeUserFromStorage = removeUserFromStorage;
const saveUserData = (token, role, userPersonalData) => {
    (0, storage_1.setStorageValue)(token, storage_1.storageKeys.user.token);
    (0, storage_1.setStorageValue)(role, storage_1.storageKeys.user.role);
    (0, storage_1.setStorageValue)(JSON.stringify(userPersonalData), storage_1.storageKeys.user.personalData);
};
exports.saveUserData = saveUserData;
const saveUserToken = (token) => (0, storage_1.setStorageValue)(token, storage_1.storageKeys.user.token);
exports.saveUserToken = saveUserToken;
const saveTokenPowerBi = (token, expirationToken) => {
    (0, storage_1.setStorageValue)(token, storage_1.storageKeys.user.tokenPowerbi);
    (0, storage_1.setStorageValue)(expirationToken, storage_1.storageKeys.user.expirationTokenPowerbi);
};
exports.saveTokenPowerBi = saveTokenPowerBi;
const getTokenPowerBi = () => (0, storage_1.getStorageValue)(storage_1.storageKeys.user.tokenPowerbi);
exports.getTokenPowerBi = getTokenPowerBi;
const getDateExpirationToken = () => (0, storage_1.getStorageValue)(storage_1.storageKeys.user.expirationTokenPowerbi);
exports.getDateExpirationToken = getDateExpirationToken;
const getAccessToken = () => (0, storage_1.getStorageValue)(storage_1.storageKeys.user.token);
exports.getAccessToken = getAccessToken;
const getUserRole = () => (0, storage_1.getStorageValue)(storage_1.storageKeys.user.role);
exports.getUserRole = getUserRole;
const getUserPersonalData = () => {
    const userData = (0, storage_1.getStorageValue)(storage_1.storageKeys.user.personalData);
    try {
        if (userData) {
            return JSON.parse(userData);
        }
    }
    catch (_a) {
        removeUserFromStorage();
    }
    return emptyUser;
};
exports.getUserPersonalData = getUserPersonalData;
const checkUserIsAuth = () => {
    const token = getAccessToken();
    return !!token;
};
exports.checkUserIsAuth = checkUserIsAuth;
