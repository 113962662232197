"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PessoaTipo = exports.PATTERN_EMAIL = exports.VERSION = exports.ENCRYPTION_KEY = exports.ROUTE_BASENAME = exports.BASE_FRONT_URL = exports.PORTAL_API_URL = void 0;
_a = process.env, exports.PORTAL_API_URL = _a.REACT_APP_PORTAL_API_URL, exports.BASE_FRONT_URL = _a.REACT_APP_BASE_FRONT_URL, exports.ROUTE_BASENAME = _a.REACT_APP_ROUTE_BASENAME;
exports.ENCRYPTION_KEY = 'uNDxaABR9TFJV4LzJEeKxRzhSVXt3qU7';
exports.VERSION = `${process.env.CURRENT_VERSION}-${process.env.BUILD_NUMBER}`;
exports.PATTERN_EMAIL = /^[a-z,A-z,0-9,@,.,_,-]+$/;
var PessoaTipo;
(function (PessoaTipo) {
    PessoaTipo["FISICA"] = "PHYSICAL_PERSON";
    PessoaTipo["JURIDICA"] = "JURIDICAL_PERSON";
})(PessoaTipo = exports.PessoaTipo || (exports.PessoaTipo = {}));
