"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const react_router_dom_1 = require("react-router-dom");
require("./UserList.scss");
const icons_1 = require("@ant-design/icons");
const ai_1 = require("react-icons/ai");
const md_1 = require("react-icons/md");
const si_1 = require("react-icons/si");
const components_1 = require("../../../components");
const RoutesPaths_1 = require("../../../routes/RoutesPaths");
const users_1 = require("../../../utils/users");
const format_1 = require("../../../utils/format");
const httpService_1 = require("../../../services/httpService");
const strings_1 = require("../../../constants/strings");
const userRoles_1 = require("../../../constants/userRoles");
const UserList = () => {
    const httpService = new httpService_1.HttpService();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [usersList, setUsersList] = (0, react_1.useState)([]);
    const [paginationInfo, setPaginationInfo] = (0, react_1.useState)({
        current: 1,
        pageSize: 1,
        total: 0,
    });
    const resendEmailForUser = (userId) => {
        try {
            httpService.get(`/email/first-access/${userId}`);
            antd_1.message.success(strings_1.RegisterMessages.resendEmailUser);
        }
        catch (error) {
            antd_1.message.error(strings_1.RegisterMessages.resendEmailUserError);
        }
    };
    const columns = [
        {
            title: 'Nome',
            key: 'names',
            // sorter: (a: IUserListItem, b: IUserListItem) => a.fullName.localeCompare(b.fullName),
            render: (text, { typePerson, fullName, companyName }) => typePerson === 'PHYSICAL_PERSON' ? fullName : companyName,
        },
        {
            title: 'CPF / CNPJ',
            key: 'registerCode',
            render: (text, { typePerson, cpf, cnpj }) => typePerson === 'PHYSICAL_PERSON' ? (cpf ? (0, format_1.formatCPF)(cpf) : cpf) : cnpj ? (0, format_1.formatCNPJ)(cnpj) : cnpj,
        },
        {
            title: 'Email',
            dataIndex: 'username',
            key: 'username',
            sorter: (a, b) => a.username && a.username.localeCompare(b.username),
        },
        {
            title: 'Status',
            dataIndex: 'userStatus',
            key: 'userStatus',
            render: (text, user) => {
                return user.userStatus !== 'ACTIVATED' ? (react_1.default.createElement(icons_1.CheckCircleOutlined, { style: { color: 'red' }, title: "Inativo" })) : (react_1.default.createElement(icons_1.CheckCircleOutlined, { style: { color: 'green' }, title: "Ativo" }));
            },
        },
        {
            title: 'Ações',
            dataIndex: 'userId',
            key: 'actions',
            render: (text, user) => (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("div", { className: "actions" },
                    react_1.default.createElement(react_router_dom_1.Link, { to: `${RoutesPaths_1.routesPaths.userEdit}/${user.userId}`, title: "Editar" },
                        react_1.default.createElement(ai_1.AiFillEdit, { size: 20 })),
                    user.userStatus !== 'ACTIVATED' ? (react_1.default.createElement("a", { onClick: () => resendEmailForUser(user.userId) },
                        react_1.default.createElement(si_1.SiMinutemailer, { size: 20, title: "Reenviar email" }))) : (react_1.default.createElement("a", { title: "Usu\u00E1rio j\u00E1 ativo" },
                        react_1.default.createElement(md_1.MdMarkEmailRead, { size: 20 })))))),
        },
    ];
    const fetchData = (page) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const users = yield httpService.get('users/listAll');
            setUsersList(users.slice((page - 1) * 40, page * 40));
            setPaginationInfo({
                current: page,
                pageSize: 40,
                total: usersList.length,
            });
        }
        catch (error) {
            antd_1.message.error(strings_1.RequestErrorDefault);
        }
        finally {
            setIsLoading(false);
        }
    });
    (0, react_1.useEffect)(() => {
        if (isLoading) {
            fetchData(1);
        }
    }, []);
    if ((0, users_1.getUserRole)() === userRoles_1.ROLES.USER)
        return react_1.default.createElement(react_router_dom_1.Navigate, { replace: true, to: "/" });
    return (react_1.default.createElement(components_1.MainWrapper, null,
        react_1.default.createElement("div", { className: "UserList" },
            react_1.default.createElement(antd_1.Spin, { tip: "Carregando...", spinning: isLoading },
                react_1.default.createElement(antd_1.Row, { className: "table-header", justify: "space-between" },
                    react_1.default.createElement("h1", { className: "user-list-title" },
                        "Usu\u00E1rios\u00A0",
                        react_1.default.createElement("span", { className: "user-list-quantity" },
                            "(",
                            usersList.length,
                            ")")),
                    react_1.default.createElement(antd_1.Button, { className: "add-button", onClick: () => navigate(RoutesPaths_1.routesPaths.userPreRegister) }, "Adicionar novo")),
                react_1.default.createElement(antd_1.Table, { dataSource: usersList, columns: columns, rowKey: "userId", pagination: false, className: "user-list-table" }),
                react_1.default.createElement(antd_1.Pagination, { className: "pagination-container", onChange: (page) => {
                        fetchData(page);
                    }, defaultCurrent: 1, current: paginationInfo.current, pageSize: paginationInfo.pageSize, total: paginationInfo.total })))));
};
exports.default = UserList;
